<template>
  <h-nav :wild="true" />
  <div class="main row">
    <div class="library ten columns offset-by-one">
      <div class="search">
        <div class="form">
          <input
            v-model="search"
            type="text"
            placeholder="请输入关键词"
            @keypress.enter="onSearch"
          />
          <span class="button" @click="onSearch">搜索</span>
        </div>
        <div class="menu">
          <router-link to="/library/kuaishou" class="item" :class="{active:plant === 'kuaishou'}">
          <img src="../assets/auth_kwai.png" alt="kuaishou">
            快手
          </router-link>
          <router-link to="/library/douyin" class="item" :class="{active:plant === 'douyin'}">
            <img src="../assets/auth_douyin.png" alt="douyin">
            抖音
          </router-link>
        </div>
      </div>
      <div class="top">
        <div class="hot">
          <div class="head">
            <h1>热销爆款</h1>
            <router-link :to="'/list/hot/' + plant + '?title=热销爆款'"
              >更多<span class="iconfont icon-Doublearrowright"></span
            ></router-link>
          </div>
          <h-cardlist class="cards" :list="hots" />
        </div>
        <div class="today">
          <div class="head">
            <h1>今日上新</h1>
            <router-link :to="'/list/today/' + plant + '?title=今日上新'"
              >更多<span class="iconfont icon-Doublearrowright"></span
            ></router-link>
          </div>
          <div class="box">
            <div class="pos">
              <h-samplelist :list="todays" />
            </div>
          </div>
        </div>
      </div>
      <div class="nine">
        <div class="head">
          <h1>9.9福利</h1>
          <router-link :to="'/list/9_9/' + plant + '?title=9.9福利'"
            >更多<span class="iconfont icon-Doublearrowright"></span
          ></router-link>
        </div>
        <h-rowlist :list="p99s" />
      </div>
      <div class="content">
        <div class="category">
          <div class="menu"><span class="iconfont icon-menu"></span>分类</div>
          <ul>
            <li
              v-for="item in categories"
              :key="item.value"
              :class="{ active: cate === item.value }"
              @click="onActive(item.value)"
            >
              <p>{{ item.label }}</p>
            </li>
          </ul>
        </div>
        <h-itemlist :list="datas" />
        <h-loader
          :disable="page >= 0 && datas.length >= count"
          @load="loadData"
        />
      </div>
    </div>
  </div>
  <!-- <h-footer :wild="true" /> -->
</template>

<script>
import Nav from "../components/Nav.vue";
// import Footer from "../components/Footer.vue";
import ItemLists from "../components/ItemLists.vue";
import ItemSampleLists from "../components/ItemSampleLists.vue";
import ItemCardLists from "../components/ItemCardLists.vue";
import ItemRowLists from "../components/ItemRowLists.vue";
import Loader from "../components/Loader.vue";

import { request } from "../utils/request";

export default {
  name: "Library",
  components: {
    "h-nav": Nav,
    // "h-footer": Footer,
    "h-itemlist": ItemLists,
    "h-samplelist": ItemSampleLists,
    "h-cardlist": ItemCardLists,
    "h-rowlist": ItemRowLists,
    "h-loader": Loader,
  },
  data() {
    return {
      categories: [{ value: 0, label: "全部" }],
      search: "",
      plant: "",
      cate: 0,
      datas: [],
      loading: false,
      page: -1,
      count: 0,
      hots: [],
      todays: [],
      p99s: [],
    };
  },
  created() {
    const { plant } = this.$route.params;
    this.plant = plant || "kuaishou";
    this.initLoad();
  },
  watch: {
    $route(r) {
      if (r.params.plant !== this.plant) {
        this.plant = r.params.plant;
        this.initLoad();
      }
    },
  },
  methods: {
    initLoad() {
      request("/products/categories", { plant: this.plant }).then((r) => {
        this.categories = [{ value: 0, label: "全部" }].concat(r);
      });
      this.loadHot();
      this.loadToday();
      this.load9_9();
      this.loadData();
    },
    loadHot() {
      request("/products/hot/hot", {
        plant: this.plant,
        page: 0,
        size: 10,
      }).then((r) => {
        this.hots = r.list.map((i) => {
          let price = Math.min(...i.skuList.map((j) => j.skuSalePrice || 0));
          return Object.assign(i, {
            commission: Math.floor(i.teamcommission / 10),
            income: ((price * i.teamcommission) / 10 / 100 / 100)
              .toFixed(2)
              .replace(/\.(\d)0/, ".$1")
              .replace(".0", ""),
            price: (price / 100)
              .toFixed(2)
              .replace(/\.(\d)0/, ".$1")
              .replace(".0", ""),
          });
        });
      });
    },
    loadToday() {
      request("/products/hot/today", {
        plant: this.plant,
        page: 0,
        size: 10,
      }).then((r) => {
        this.todays = r.list.map((i) => {
          let price = Math.min(...i.skuList.map((j) => j.skuSalePrice || 0));
          return Object.assign(i, {
            commission: Math.floor(i.teamcommission / 10),
            income: ((price * i.teamcommission) / 10 / 100 / 100)
              .toFixed(2)
              .replace(/\.(\d)0/, ".$1")
              .replace(".0", ""),
            price: (price / 100)
              .toFixed(2)
              .replace(/\.(\d)0/, ".$1")
              .replace(".0", ""),
          });
        });
      });
    },
    load9_9() {
      request("/products/hot/9_9", {
        plant: this.plant,
        page: 0,
        size: 10,
      }).then((r) => {
        this.p99s = r.list.map((i) => {
          let price = Math.min(...i.skuList.map((j) => j.skuSalePrice || 0));
          return Object.assign(i, {
            commission: Math.floor(i.teamcommission / 10),
            income: ((price * i.teamcommission) / 10 / 100 / 100)
              .toFixed(2)
              .replace(/\.(\d)0/, ".$1")
              .replace(".0", ""),
            price: (price / 100)
              .toFixed(2)
              .replace(/\.(\d)0/, ".$1")
              .replace(".0", ""),
          });
        });
      });
    },
    onSearch() {
      this.$router.push(
        "/list/search/" + this.plant + "?key=" + this.search
      );
    },
    onActive(cate) {
      if (cate !== this.cate) {
        this.cate = cate;
        this.page = -1;
        this.loadData();
      }
    },
    loadData() {
      if (this.loading || (this.page > 0 && this.datas.length >= this.count)) {
        return;
      }
      this.loading = true;
      let page = this.page + 1;
      request("/products/hot", {
        plant: this.plant,
        cate: this.cate,
        page,
        size: 10,
      })
        .then((r) => {
          this.page = page;
          this.count = r.count;
          this.datas = (page === 0 ? [] : this.datas).concat(
            ...r.list.map((i) => {
              let price = Math.min(
                ...i.skuList.map((j) => j.skuSalePrice || 0)
              );
              return Object.assign(i, {
                commission: Math.floor(i.teamcommission / 10),
                income: ((price * i.teamcommission) / 10 / 100 / 100)
                  .toFixed(2)
                  .replace(/\.(\d)0/, ".$1")
                  .replace(".0", ""),
                price: (price / 100)
                  .toFixed(2)
                  .replace(/\.(\d)0/, ".$1")
                  .replace(".0", ""),
              });
            })
          );
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log("error", e);
        });
    },
  },
};
</script>

<style scoped>
/* .arrow{
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojMjMxZjIwO308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxnIGRhdGEtbmFtZT0iTGF5ZXIgMiIgaWQ9IkxheWVyXzIiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTE2Ljg4LDE1LjUzLDcsNS42NkExLDEsMCwwLDAsNS41OSw3LjA3bDkuMDYsOS4wNi04LjgsOC44YTEsMSwwLDAsMCwwLDEuNDFoMGExLDEsMCwwLDAsMS40MiwwbDkuNjEtOS42MUEuODUuODUsMCwwLDAsMTYuODgsMTUuNTNaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjYuNDYsMTUuNTMsMTYuNTgsNS42NmExLDEsMCwwLDAtMS40MSwxLjQxbDkuMDYsOS4wNi04LjgsOC44YTEsMSwwLDAsMCwwLDEuNDFoMGExLDEsMCwwLDAsMS40MSwwbDkuNjItOS42MUEuODUuODUsMCwwLDAsMjYuNDYsMTUuNTNaIi8+PC9nPjwvc3ZnPg==);
}
.arrow::after{
  content: "";
} */
.search {
  display: flex;
  flex-direction: row;
}
.search .form {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  flex: 1;
}
.search input {
  margin: 0;
  border: 1px solid #d1d1d1;
  border-radius: 4px 0 0 4px;
  border-right: none;
  width: 40%;
}
.search .button {
  margin: 0;
  background-color: #fb393d;
  color: white;
  border: 1px solid #fb393d;
  border-radius: 0 4px 4px 0;
  border-left: none;
}
.search .menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  margin: 10px 0;
}
.search .menu .item{
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search .menu .item img{
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 4px;
}
.search .menu .item:first-of-type{
  border-right: 1px solid #f5f5f5;
}
.search .menu .item.active{
  color: #fb393d;
}
.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.head h1 {
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: left;
  margin: 1rem 0;
}
.head a {
  text-decoration: none;
  color: #666;
  font-size: 13px;
}
.head a:hover {
  color: #333;
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.top .hot {
  width: calc(64% - 12px);
  background-color: #fb393d;
  border-radius: 6px;
  padding: 0 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.top .hot .head h1 {
  color: white;
}
.top .hot .head a {
  color: white;
}
.top .hot .cards {
  flex: 1;
}
.top .today {
  width: 36%;
  background-color: white;
  border-radius: 6px;
  padding: 0 1rem 1rem 1rem;
  box-sizing: border-box;
}
.top .today .head h1 {
  color: #fb393d;
  text-align: left;
  margin: 1rem 0;
}
.top .today .box {
  position: relative;
  padding-top: 78%;
  padding-bottom: 2rem;
}
.top .today .box .pos {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.nine {
  background-color: white;
  border-radius: 6px;
  padding: 0 1rem 1rem 1rem;
  margin-top: 1rem;
}
.nine h1 {
  color: #fb393d;
}
.content {
  padding-bottom: 1rem;
}
.content .category {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  margin: 1rem 0;
}
.content .category .menu {
  font-size: 13px;
  color: #fb393d;
  font-weight: 500;
  margin-left: 1rem;
}
.content .category .menu span {
  font-size: 13px;
}
.content .category ul {
  flex: 1;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0 0.5rem;
  margin: 0;
}
.content .category ul li {
  font-size: 13px;
  font-weight: 400;
  padding: 1rem 0.5rem;
  margin: 0;
}
.content .category ul li.active p {
  border-bottom: 1px solid #fb393d;
  color: #fb393d;
}
.content .category ul li p {
  margin: 0;
  font-size: 13px;
  color: #666;
}
.content .category ul li:hover p {
  color: #fb393d;
}
</style>
