<template>
  <h-nav />
  <div class="main">
    <section class="section-1">
      <div class="bg">
        <div class="row">
          <div class="section eight columns offset-by-two">
            <div class="slogan content">
              <h1>__</h1>
              <img class="img" src="../assets/slogan.png" alt="slogan" />
              <p>
                专业直播电商服务平台，助力达人实现持续流量变现，帮助商家快速通过直播打造爆款。
              </p>
            </div>
            <div class="mini content">
              <div class="button main-fg">红透网络小程序</div>
              <div class="img">
                <img class="qr" src="../assets/qr.png" alt="qr" />
                <img class="tag" src="../assets/tag.png" alt="qr" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-v row">
      <div class="section eight columns offset-by-two">
        <video
          autoplay
          muted
          controls
          src="http://hongtou.cn-sh2.ufileos.com/static/video.mp4"
        ></video>
      </div>
    </section>
    <section class="section-2 row">
      <div class="section eight columns offset-by-two">
        <img class="img" src="../assets/library.png" alt="library" />
        <div class="library content">
          <h1>直播选品库</h1>
          <p>高效选品 一应俱全<br />专属推荐 一键上架</p>
          <router-link class="button main-bg" to="/library"
            >查看详情</router-link
          >
        </div>
      </div>
    </section>

    <section class="section-3 row">
      <div class="section eight columns offset-by-two">
        <div class="data content">
          <h1>直播数据分析</h1>
          <p>
            直播数据分析，达人、爆品一目了然<br />助力打造爆款，全面提升销量。
          </p>
          <router-link class="button main-bg" to="/data">查看详情</router-link>
        </div>
        <div class="img">
          <img src="../assets/data.png" alt="data" />
        </div>
      </div>
    </section>

    <section class="section-4 row">
      <div class="section eight columns offset-by-two">
        <img class="img" src="../assets/base.png" alt="base" />
        <div class="base content">
          <h1>直播专场基地</h1>
          <p>
            全国品牌专场任你选（达人根据位置选择合适的品牌直播间或直播基地，轻松开专场）
          </p>
          <router-link class="button main-bg" to="/base">查看详情</router-link>
        </div>
      </div>
    </section>

    <section class="section-5 row">
      <div class="section eight columns offset-by-two">
        <div class="numbers">
          <div>
            <h5>20000 <span>+</span></h5>
            <p>合作达人</p>
          </div>
          <div>
            <h5>3000 <span>+</span></h5>
            <p>合作商家</p>
          </div>
          <div>
            <h5>500 <span>+</span></h5>
            <p>合作基地</p>
          </div>
          <div>
            <h5>10000 <span>+</span></h5>
            <p>上架SKU</p>
          </div>
          <div>
            <h5>50000000 <span>+</span></h5>
            <p>成交GMV</p>
          </div>
        </div>
      </div>
    </section>

    <section class="section-6 row" id="operating">
      <div class="circle" alt="bg"><div></div></div>
      <div class="section eight columns offset-by-two">
        <div class="services">
          <div>
            <img src="../assets/services_1.png" alt="services" />
            <h5>专场招商</h5>
            <p>
              为达人提供专场招商服务，
              大牌爆品，福利引流，利润变现合理安排，快速提供专 场组货。
            </p>
          </div>
          <div>
            <img src="../assets/services_2.png" alt="services" />
            <h5>客服外包</h5>
            <p>
              助商家达人解决客服问题，
              提升店铺评分，保障店铺层级。确保提升客户满意 度。
            </p>
          </div>
          <div>
            <img src="../assets/services_3.png" alt="services" />
            <h5>代运营</h5>
            <p>
              店铺整体打理，协助达人商
              家完成直播电商产品的上架、运营、售前、售后等，解放更
              多时间专注产品及内容。
            </p>
          </div>
          <div>
            <img src="../assets/services_4.png" alt="services" />
            <h5>全案合作</h5>
            <p>
              直播电商整体解决方案，产
              品包装、直播机制、达人分销，快速触达精准达人，引爆直播 渠道。
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section-7 row">
      <div class="bg-box">
        <div class="circle" alt="bg"><div></div></div>
      </div>
      <div class="section eight columns offset-by-two">
        <div class="cooperate">
          <div>
            <h5>合作达人</h5>
            <div class="imgs">
              <div>
                <img src="../assets/eimg/e_10.jpg" alt="img" />
                <img src="../assets/eimg/e_100.jpg" alt="img" />
                <img src="../assets/eimg/e_1000.jpg" alt="img" />
              </div>
              <div>
                <img src="../assets/eimg/e_300.jpg" alt="img" />
                <img src="../assets/eimg/e_400.jpg" alt="img" />
                <img src="../assets/eimg/e_500.jpg" alt="img" />
              </div>
            </div>
          </div>
          <div>
            <h5>合作品牌</h5>
            <div class="imgs">
              <div>
                <img src="../assets/eimg/e_50.jpg" alt="img" />
                <img src="../assets/eimg/e_150.jpg" alt="img" />
                <img src="../assets/eimg/e_250.jpg" alt="img" />
              </div>
              <div>
                <img src="../assets/eimg/e_350.jpg" alt="img" />
                <img src="../assets/eimg/e_450.jpg" alt="img" />
                <img src="../assets/eimg/e_650.jpg" alt="img" />
              </div>
            </div>
          </div>
          <div>
            <h5>合作媒体</h5>
            <div class="imgs">
              <div>
                <img src="../assets/eimg/e_130.jpg" alt="img" />
                <img src="../assets/eimg/e_230.jpg" alt="img" />
                <img src="../assets/eimg/e_1130.jpg" alt="img" />
              </div>
              <div>
                <img src="../assets/eimg/e_1300.jpg" alt="img" />
                <img src="../assets/eimg/e_1430.jpg" alt="img" />
                <img src="../assets/eimg/e_1630.jpg" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <h-float />
  <h-footer />
</template>

<script>
import Nav from "../components/Nav.vue";
import Float from "../components/Float.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    "h-nav": Nav,
    "h-float": Float,
    "h-footer": Footer,
  },
};
</script>

<style scoped>
.main .section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.main .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main .button {
  border: none;
}
.main .button:hover {
  border: none;
}
.section-1 {
  width: 100vw;
}
.section-1 .bg {
  box-sizing: border-box;
  width: 100%;
  background: transparent;
  border-bottom: 180px solid transparent;
  border-left: 100vw solid #fb393d;
}
.section-1 .row {
  width: 100vw;
  margin-left: -100vw;
}
.section-1 .section {
  padding: 60px 0 20px 0;
  text-align: left;
}
.section-1 .section .slogan {
  align-items: flex-start;
  width: 54%;
  padding-left: 10%;
  box-sizing: border-box;
}
.section-1 .section .slogan h1 {
  margin: 0;
  padding: 0;
  color: white;
}
.section-1 .section .slogan .img {
  width: 50%;
  margin: 44px 0 24px 0;
}
.section-1 .section .slogan p {
  max-width: 275px;
  text-align: left;
  font-size: 13px;
  color: white;
}
.section-1 .section .mini {
  flex-direction: column-reverse;
  width: 30%;
  box-sizing: border-box;
  padding-right: 10%;
}
.section-1 .section .mini .img {
  width: 80%;
  padding-bottom: 6px;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-1 .section .mini .img:hover {
  opacity: 1;
}
.section-1 .section .mini .img .qr {
  width: 100%;
  border-radius: 4px;
}
.section-1 .section .mini .img .tag {
  width: 12px;
  height: 6px;
}
.section-1 .section .mini .button {
  color: #fb393d;
  background-color: white;
  font-size: 13px;
  border-radius: 4px;
}
.section-1 .section .mini .button:hover {
  color: #fb393d;
}
.section-1 .section .mini .button:hover ~ .img {
  opacity: 1;
}

.section .content h1 {
  font-size: 27px;
  font-weight: 800;
  color: #d83a39;
  line-height: 18px;
}
.section .content p {
  font-size: 13px;
  font-weight: 500;
  color: #999999;
}
.section .content .button {
  color: #ffd7d6;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50rem;
}
.section .content .button:hover {
  color: white;
  box-shadow: 0px 11px 31px 0px rgba(216, 58, 57, 0.21);
}

.section-v {
  width: 100vw;
  margin-top: -160px;
}
.section-v video{
  width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid #F5F5F5;
}

.section-2 {
  width: 100vw;
  margin-top: 80px;
}
.section-2 .section {
  justify-content: space-around;
  background-color: white;
  border-radius: 6px;
}
.section-2 .img {
  width: 54%;
  align-self: flex-end;
  margin-top: 40px;
}
.section-2 .library {
  width: 30%;
  padding-top: 12px;
}

.section-3 {
  margin-top: 80px;
}
.section-3 .section {
  justify-content: space-around;
}
.section-3 .img {
  width: 54%;
  box-sizing: border-box;
}
.section-3 .img img {
  width: 80%;
}
.section-3 .data {
  width: 30%;
  padding-top: 12px;
}

.section-4 {
  margin-top: 80px;
  padding: 50px 0;
  background-color: white;
}
.section-4 .section {
  justify-content: space-around;
}
.section-4 .img {
  width: 54%;
}
.section-4 .base {
  width: 30%;
  padding-top: 12px;
}

.section-5 {
  background-image: url(../assets/numbers.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.section-5 .numbers {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 0 100px 0;
}
.section-5 .numbers h5 {
  font-size: 36px;
  font-family: DIN;
  font-weight: bold;
  color: #956000;
  margin: 0;
  padding: 0;
  text-align: left;
}
.section-5 .numbers span {
  font-size: 27px;
}
.section-5 .numbers p {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #7d5100;
  margin: 0;
  padding: 0;
  text-align: left;
}

.circle {
  overflow: hidden;
}
.circle div {
  box-sizing: border-box;
  border-radius: 50%;
  border-style: solid;
  border-color: white;
}

.section-6 .circle {
  width: 100px;
  height: 120px;
  float: right;
}
.section-6 .circle div {
  width: 200px;
  height: 200px;
  margin-top: -80px;
  border-width: 40px;
}

.section-6 .services {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -60px;
}
.section-6 .services > div {
  background-color: white;
  padding: 20px;
  width: 23%;
  box-sizing: border-box;
  border-radius: 2px;
}
.section-6 .services img {
  width: 52px;
}
.section-6 .services h5 {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  margin: 4px 0;
  padding: 0;
}
.section-6 .services p {
  font-size: 11px;
  font-weight: 500;
  color: #999999;
  margin: 0;
  padding: 0;
}

.section-7 {
  padding-bottom: 80px;
}
.section-7 .bg-box {
  width: 0;
  height: 0;
  margin-top: 104px;
  float: left;
}
.section-7 .circle {
  width: 260px;
  height: 240px;
}
.section-7 .circle div {
  width: 260px;
  height: 260px;
  border-width: 50px;
}

.section-7 .cooperate {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.section-7 .cooperate > div {
  width: 30%;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 15px 30px;
  border-radius: 2px;
}
.section-7 .cooperate > div:nth-child(1) {
  background-image: url(../assets/cooperate_1.png);
}
.section-7 .cooperate > div:nth-child(2) {
  background-image: url(../assets/cooperate_2.png);
}
.section-7 .cooperate > div:nth-child(3) {
  background-image: url(../assets/cooperate_3.png);
}
.section-7 .cooperate .imgs {
  margin-top: 5px;
}
.section-7 .cooperate .imgs > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.section-7 .cooperate .imgs img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid white;
}

.section-7 .cooperate h5 {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  margin: 0;
  padding: 0;
}
</style>
