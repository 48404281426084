<template>
  <h-nav :wild="true" />
  <div class="main row">
    <div class="library ten columns offset-by-one">
      <div class="content">
        <div v-if="type === 'search'" class="search">
          <div class="form">
            <input
              v-model="search"
              type="text"
              placeholder="请输入关键词"
              @keyup.enter="onSearch"
            />
            <span class="button" @click="onSearch">搜索</span>
          </div>
        </div>
        <div v-else class="head">
          <h1>{{ title }}</h1>
        </div>
        <h-itemlist :list="datas" />
        <h-loader
          :disable="page >= 0 && datas.length >= count"
          @load="loadData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import ItemLists from "../components/ItemLists.vue";
import Loader from "../components/Loader.vue";

import { request } from "../utils/request";

export default {
  name: "Library",
  components: {
    "h-nav": Nav,
    // "h-footer": Footer,
    "h-itemlist": ItemLists,
    "h-loader": Loader,
  },
  data() {
    return {
      plant: "",
      title: "",
      type: "",
      search: "",
      datas: [],
      loading: false,
      page: -1,
      count: 0,
    };
  },
  watch: {
    $route() {
      const { title, key } = this.$route.query;
      const { type, plant } = this.$route.params;
      if (!(type === this.type && plant === this.plant)) {
        this.plant = plant;
        this.type = type;
        this.search = key;
        this.title = title;
        this.datas = [];
        this.page = -1;
        this.count = 0;
        this.loadData();
      }
    },
  },
  created() {
    const { title, key } = this.$route.query;
    const { type, plant } = this.$route.params;
    this.plant = plant;
    this.title = title;
    this.search = key;
    this.type = type;
    this.loadData();
  },
  methods: {
    onSearch() {
      this.datas = [];
      this.loading = false;
      this.page = -1;
      this.count = 0;
      this.loadData();
    },
    loadData() {
      if (this.loading || (this.page > 0 && this.datas.length >= this.count)) {
        return;
      }
      this.loading = true;
      let page = this.page + 1;
      request(
        "/products/hot" + (this.type !== "search" ? "/" + this.type : ""),
        {
          plant: this.plant,
          key: this.type !== "search" ? undefined : this.search,
          page,
          size: 10,
        }
      )
        .then((r) => {
          this.page = page;
          this.count = r.count;
          this.datas = this.datas.concat(
            ...r.list.map((i) => {
              let price = Math.min(
                ...i.skuList.map((j) => j.skuSalePrice || 0)
              );
              return Object.assign(i, {
                commission: Math.floor(i.teamcommission / 10),
                income: ((price * i.teamcommission) / 10 / 100 / 100)
                  .toFixed(2)
                  .replace(/\.(\d)0/, ".$1")
                  .replace(".0", ""),
                price: (price / 100)
                  .toFixed(2)
                  .replace(/\.(\d)0/, ".$1")
                  .replace(".0", ""),
              });
            })
          );
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log("error", e);
        });
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  flex-direction: row;
}
.search .form {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  flex: 1;
}
.search input {
  margin: 0;
  border: 1px solid #d1d1d1;
  border-radius: 4px 0 0 4px;
  border-right: none;
  width: 40%;
}
.search .button {
  margin: 0;
  background-color: #fb393d;
  color: white;
  border: 1px solid #fb393d;
  border-radius: 0 4px 4px 0;
  border-left: none;
}
.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.head h1 {
  font-size: 18px;
  font-weight: bold;
  text-align: left;

  color: #fb393d;
  margin: 1rem 0;
}

.content {
  padding-bottom: 1rem;
}
</style>
