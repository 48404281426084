<template>
  <h-swiper
    class="swiper h-pagination"
    :slides-per-view="1"
    :space-between="10"
    :pagination="{ clickable: true }"
  >
    <h-swiperslide class="slide" v-for="(col, i) in datas" :key="i">
      <div class="col">
        <div class="block" v-for="(row, j) in col" :key="j">
          <router-link
            class="item"
            v-for="item in row"
            :key="item.id"
            :to="'/detail/' + item.id"
            target="_blank"
          >
            <div
              class="img"
              :style="{
                'background-image': 'url(' + item.image + ')',
              }"
            >
              <div></div>
            </div>
            <div class="info">
              <h5>
                <span
                  v-if="item.plant === 'douyin'"
                  class="iconfont icon-douyin1"
                ></span>
                <span v-else class="iconfont icon-kuaishou"></span>
                {{ item.title }}
              </h5>
              <p>
                预估赚<span>¥{{ item.income }}</span>
              </p>
              <div>
                <p>
                  直播价<span>¥{{ item.price }}</span>
                </p>
                <p>
                  佣金率<span>{{ item.commission }}%</span>
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </h-swiperslide>
  </h-swiper>
</template>

<script>
import chunk from "underscore/modules/chunk";
import SwiperCore, { Pagination, Mousewheel, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination, Mousewheel, Autoplay]);

export default {
  name: "ItemCardLists",
  components: {
    "h-swiper": Swiper,
    "h-swiperslide": SwiperSlide,
  },
  props: {
    list: { type: Array },
  },
  computed: {
    datas() {
      return chunk(this.list || [], 4)
        .filter((i) => i.length === 4)
        .map((i) => chunk(i, 2));
    },
  },
};
</script>

<style scoped>
.swiper {
  margin: 0;
}
.slide {
  width: 100%;
  height: 100%;
}
.col {
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.item {
  width: 49%;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  text-decoration: none;
  color: #666;
}
.item .img {
  width: 42%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  overflow: hidden;
}
.item .img > div {
  width: 100%;
}
.item .img > div::after {
  width: 100%;
  content: " ";
  display: block;
  padding-top: 100%;
}
.item .info {
  width: 58%;
  box-sizing: border-box;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
}
.item .info h5 {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  line-height: 18px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item .info h5 .iconfont{
  color: #fb393d;
  font-size: 13px;
  font-weight: 500;
}
.item .info > div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1;
}
.item .info p {
  margin: 0;
  text-align: left;
  font-size: 12px;
}
.item .info p span {
  color: #fb393d;
  font-size: 16px;
}
.item .info > div > p {
  flex: 1;
}
</style>
