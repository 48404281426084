<template>
  <h-nav />
  <div class="main">
    <div v-if="qr" class="content">
      <img :src="qr" />
      <h5>微信扫一扫，绑定快手账号即可使用</h5>
    </div>
  </div>
  <h-footer />
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Data",
  components: {
    "h-nav": Nav,
    "h-footer": Footer,
  },
  data() {
    return {
      qr: "",
    };
  },
  created() {
    const { key, time } = this.$route.query;
    this.qr = `/api/weapi/qr?page=${encodeURIComponent(
      "pages/login/index"
    )}&scene=${encodeURIComponent("k=" + key + "&t=" + time)}`;
  },
};
</script>

<style scoped>
.content {
  margin: 1rem 0;
  min-height: calc(100vh - 324px - 2rem);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content img{
  width: 280px;
  height: 280px;
  border-radius: 8px;
  margin: 10px 0;
}
</style>
