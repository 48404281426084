<template>
  <div class="list">
    <router-link
      class="item"
      v-for="item in datas"
      :key="item.id"
      :to="'/detail/' + item.id"
      target="_blank"
    >
      <div
        class="img"
        :style="{
          'background-image': 'url(' + item.image + ')',
        }"
      >
        <div></div>
      </div>
      <div class="info">
        <h5>
          <span
            v-if="item.plant === 'douyin'"
            class="iconfont icon-douyin1"
          ></span>
          <span v-else class="iconfont icon-kuaishou"></span>
          {{ item.title }}
        </h5>
        <p>
          预估赚<span>¥{{ item.income }}</span>
        </p>
        <div class="line">
          <p>
            直播价<span>¥{{ item.price }}</span>
          </p>
          <p>
            佣金率<span>{{ item.commission }}%</span>
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ItemSampleLists",
  props: {
    list: { type: Array },
  },
  computed: {
    datas() {
      return this.list;
    },
  },
};
</script>

<style scoped>
.list {
  padding: 0;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow: scroll hidden;
}
.item {
  width: 180px;
  margin-right: 8px;
  text-decoration: none;
  color: #666;
}
.list .item:last-of-type {
  margin-right: 0;
}
.item .img {
  width: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  overflow: hidden;
}
.item .img > div {
  width: 100%;
}
.item .img > div::after {
  width: 100%;
  content: " ";
  display: block;
  padding-top: 100%;
}
.item .info {
  box-sizing: border-box;
  padding: 0 8px;
}
.item .info h5 {
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  max-width: 100%;
  color: #333;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  line-height: 18px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item .info h5 .iconfont{
  color: #fb393d;
  font-size: 13px;
  font-weight: 500;
}
.item .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item .info p {
  margin: 0;
  text-align: left;
  font-size: 12px;
}
.item .info p span {
  color: #fb393d;
  font-size: 16px;
}
.item .info > div > p {
  flex: 1;
}
.item .info .line {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-end;
  flex: 1;
}
.item .info .line p {
  flex: 1;
}
</style>
