<template>
  <div class="footer-bar one column offset-by-ten">
    <ul>
      <li>
        <a href="https://jinshuju.net/f/M0Cwso" target="_blank">
          <img src="../assets/form_1.png" alt="form" />
          <p>商务合作</p>
        </a>
      </li>
      <li>
        <a href="https://jinshuju.net/f/XbtunL" target="_blank">
          <img src="../assets/form_2.png" alt="form" />
          <p>达人合作</p>
        </a>
      </li>
      <li>
        <a href="https://jinshuju.net/f/peYwMT" target="_blank">
          <img src="../assets/form_3.png" alt="form" />
          <p>基地合作</p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Float",
};
</script>

<style scoped>
.footer-bar {
  top: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: white;
  box-shadow: 0px 5px 11px 0px rgba(64, 64, 64, 0.72);
  border-radius: 3px;
}
.footer-bar ul {
  list-style: none;
  margin: 0 auto;
  padding-top: 1rem;
}
.footer-bar ul li {
  border-bottom: 1px solid #f2f2f2;
  margin: 0;
  padding: 0.5rem 0;
}
.footer-bar a {
  text-decoration: none;
}
.footer-bar img {
  width: 20px;
  opacity: 0.5;
}
.footer-bar p {
  font-size: 11px;
  font-weight: 500;
  color: #999999;
  margin: 0;
  padding: 0;
  text-align: center;
}
.footer-bar ul li:hover img{
  opacity: 1;
}
</style>
