import Home from './Home.vue'
import Library from './Library.vue'
import List from './List.vue'
import Base from './Base.vue'
import Detail from './Detail.vue';
import Data from './Data.vue';
import About from './About.vue';
import Bind from './Bind.vue';
import Notfound from './Notfound.vue';

const routes = [{
    path: '/',
    component: Home
}, {
    path: '/library',
    redirect: { path: '/library/kuaishou' }
}, {
    path: '/library/:plant',
    component: Library
}, {
    path: '/base',
    component: Base
}, {
    path: '/list/:type',
    redirect: (to)=>{ console.log(to);return { path: '/list/' + to.parms.type +'/kuaishou' }}
},{
    path: '/list/:type/:plant',
    component: List
}, {
    path: '/detail/:id',
    component: Detail
}, {
    path: '/data',
    component: Data
}, {
    path: '/about',
    component: About
}, {
    path: '/bind',
    component: Bind
}, {
    path: '/:pathMatch(.*)*',
    component: Notfound
}];

export default routes;