
export function request(path, data, method) {
  method = method || 'get';
  if (method.toLowerCase() === 'get' && data) {
    path = path + '?' + Object.keys(data).map(i => (i + '=' + data[i])).join('&');
    data = null
  }
  return fetch('/api' + path, {
    method,
    body: data ? JSON.stringify(data) : null
  }).then(r => r.json())
}
