<template>
  <div class="nav main-bg">
    <div
      class="nav-bar columns"
      :class="{
        ten: wild,
        'offset-by-one': wild,
        eight: !wild,
        'offset-by-two': !wild,
      }"
    >
      <router-link to="/" class="brand">
        <img src="../assets/logo.png" alt="logo" />
      </router-link>
      <ul class="menu-list">
        <li>
          <router-link to="/library">选品库</router-link>
        </li>
        <li>
          <router-link to="/data">数据榜单</router-link>
        </li>
        <li>
          <router-link to="/base">基地专场</router-link>
        </li>
        <li>
          <router-link to="/#operating">运营服务</router-link>
        </li>
        <li>
          <router-link to="/about">关于我们</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    wild: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100vw;
  color: white;
  padding: 10px 0;
  height: 64px;
  box-sizing: border-box;
}
.nav-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.brand {
  display: block;
  height: 44px;
}
.brand img {
  height: 44px;
}
.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu-list li {
  display: inline-block;
  padding: 6px;
  margin: 0 0 0 20px;
}
.menu-list li a {
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  color: #ffd7d6;
}
.menu-list li:hover a {
  color: white;
}
</style>
