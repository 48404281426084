<template>
  <div class="footer main-bg">
    <div
      class="footer-bar columns"
      :class="{
        ten: wild,
        'offset-by-one': wild,
        eight: !wild,
        'offset-by-two': !wild,
      }"
    >
      <div class="content">
        <div class="left">
          <router-link to="/" class="brand">
            <img src="../assets/logo.png" alt="logo" />
          </router-link>
          <div class="mini">
            <img src="../assets/qr.png" alt="qr" />
            <p>红透网络小程序</p>
          </div>
        </div>
        <div class="right">
          <div class="info">
            <ul class="links">
              <li>
                <router-link to="/library">选品库</router-link>
              </li>
              <li>
                <a href="https://jinshuju.net/f/M0Cwso" target="_blank"
                  >商务合作</a
                >
              </li>
              <li>
                <router-link to="/data">数据分析</router-link>
              </li>
              <li>
                <a href="https://jinshuju.net/f/XbtunL" target="_blank"
                  >达人合作</a
                >
              </li>
              <li>
                <router-link to="/base">专场基地</router-link>
              </li>
              <li>
                <a href="https://jinshuju.net/f/peYwMT" target="_blank"
                  >基地合作</a
                >
              </li>
            </ul>
            <div class="line"></div>
            <div class="addrs">
              <div class="addr">
                <img src="../assets/address.png" alt="address" />
                <p>杭州地址<br /><span>杭州市余杭区良渚时代大厦1722</span></p>
              </div>
              <div class="addr">
                <img src="../assets/address.png" alt="address" />
                <p>徐州地址<br /><span>徐州市泉山区瑞银中心25楼</span></p>
              </div>
            </div>
          </div>
          <div class="plantform">
            <img src="../assets/douyin.png" alt="douyin" />
            <img src="../assets/kuaishou.png" alt="kuaishou" />
            <img src="../assets/taobao.png" alt="taobao" />
            <img src="../assets/weishi.png" alt="weishi" />
          </div>
        </div>
      </div>
      <div class="icp">
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >浙ICP备2021006808号-1</a
        >
        ©杭州红透网络科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    wild: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100vw;
  color: white;
  padding: 10px;
  height: 260px;
  box-sizing: border-box;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
}
.content .left {
  width: 30%;
  display: inline-block;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.content .left .brand {
  display: block;
}
.content .left .brand img {
  height: 44px;
  width: auto;
}
.content .left .mini img {
  width: 80px;
  border-radius: 4px;
}
.content .left .mini p {
  margin: 0;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: #f5f5f5;
}
.content .right {
  width: 70%;
  max-width: 580px;
}
.content .right .info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.content .right .info .line {
  background-color: white;
  opacity: 0.5;
  width: 0.5px;
  margin: 20px 0;
  align-self: stretch;
}
.content .right .info .links {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.content .right .info .links li {
  width: 50%;
  margin: 0;
  padding: 8px 0;
  text-align: right;
}

.content .right .info .links li a {
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  color: #ffd7d6;
}
.content .right .info .links li a:hover {
  color: white;
}
.content .right .info .addrs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.content .right .info .addr {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.content .right .info .addr img {
  width: 24px;
  height: auto;
}
.content .right .info .addr p {
  margin: 0 0 0 8px;
  font-size: 12px;
  font-weight: 500;
  color: white;
  text-align: left;
}
.content .right .info .addr p span {
  color: white;
  opacity: 0.8;
}
.content .right .plantform {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8px;
}
.content .right .plantform img {
  height: 42px;
  width: auto;
  margin-left: 8px;
}
.icp {
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: white;
  text-align: center;
  padding: 12px;
}
.icp a {
  color: white;
  text-decoration: none;
}
</style>
