<template>
  <div class="container">
    <div ref="map" class="map" />
  </div>
</template>

<script>
function setupMap(container, onMarker) {
  const maps = window.qq.maps;
  var map = null;
  var markers = {};

  map = new maps.Map(container, {
    center: new maps.LatLng(39.916527, 116.397128),
    zoom: 13,
  });

  function setMarker(info) {
    const { position, title, id } = info;
    let marker = new maps.Marker({
      id,
      map,
      position: new maps.LatLng(position.lat, position.lng),
      title,
    });
    maps.event.addListener(marker, "click", function () {
      onMarker(info);
    });
    return marker;
  }

  function updateMarkers(array) {
    let ls = {};
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      const {id, title, position} = element;
      let marker = markers[id];
      if (markers[id]) {
        delete markers[id];
        marker.setPosition(new maps.LatLng(position.lat, position.lng));
        marker.setTitle(title);
      } else {
        marker = setMarker(element);
      }
      ls[id] = marker;
    }
    let olds = Object.values(markers || {});
    olds.forEach((i) => i.setMap(null));
    markers = ls;
  }

  function updateCenter(position) {
    map.setCenter(new maps.LatLng(position.lat, position.lng));
  }

  return {
    map,
    update: (v, markers) => {
      console.log('update', v, markers);
      updateMarkers(markers);
      if (v) {
        updateCenter(v);
      }
    },
  };
}

export default {
  name: "Map",
  props: {
    position: {
      type: Object,
    },
    markers: {
      type: Array,
    },
  },
  watch: {
    position() {
      this.update();
    },
    markers() {
      this.update();
    },
  },
  mounted() {
    if (!this.map) {
      this.map = setupMap(this.$refs.map, (val) => {
        this.$emit("marker", val);
      });
    }
  },
  beforeUnmount() {
    this.map = null;
  },
  methods: {
    update() {
      let pos =
        this.position && this.position.lat && this.position.lng
          ? { lat: this.position.lat, lng: this.position.lng }
          : null;
      let markers = (this.markers || []).map((i) => ({
        title: i.title,
        id: i.id,
        position: { lat: i.position.lat, lng: i.position.lng },
      }));
      this.map.update(pos, markers);
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
}
.map {
  width: 100%;
  height: 100%;
}
</style>
