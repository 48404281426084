<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body {
  background-color: rgb(249, 250, 251);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.main-bg {
  background-color: #fb393d;
}

.main-fg {
  color: #fb393d;
}

.swiper.h-pagination .swiper-pagination {
  bottom: 0;
}
.swiper.h-pagination .swiper-pagination-bullet-active {
  background-color: white;
}
</style>
