<template>
  <div class="list">
    <router-link
      class="item"
      v-for="item in datas"
      :key="item.id"
      :to="'/detail/' + item.id"
      target="_blank"
    >
      <div
        class="img"
        :style="{
          'background-image': 'url(' + item.image + ')',
        }"
      >
        <div></div>
      </div>
      <div class="info">
        <h5>
          <span
            v-if="item.plant === 'douyin'"
            class="iconfont icon-douyin1"
          ></span>
          <span v-else class="iconfont icon-kuaishou"></span>
          {{ item.title }}
        </h5>
        <ul>
          <li>
            <span>¥{{ item.price }}</span>
            <p>直播价</p>
          </li>
          <li>
            <span>{{ item.commission }}%</span>
            <p>佣金率</p>
          </li>
          <li>
            <span>¥{{ item.income }}</span>
            <p>预估赚</p>
          </li>
        </ul>
      </div>
    </router-link>
    <div class="item empty" v-for="(v, i) in placeholder" :key="i"></div>
  </div>
</template>

<script>
export default {
  name: "ItemLists",
  props: {
    list: { type: Array },
  },
  computed: {
    datas() {
      return this.list;
    },
    placeholder() {
      return [{}, {}, {}].slice(
        0,
        Math.ceil((this.list || []).length / 4) * 4 - (this.list || []).length
      );
    },
  },
  created() {},
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
ul li {
  margin: 0;
  padding: 0;
}
.list {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.list .item {
  width: 24%;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 8px;
  text-decoration: none;
  color: #666;
}
.list .item.empty {
  border: none;
}
.list .item .img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.list .item .img > div {
  width: 100%;
}
.list .item .img > div::after {
  width: 100%;
  content: " ";
  display: block;
  padding-top: 86%;
}
.list .item .info {
  padding: 0 12px;
}
.list .item .info h5 {
  margin: 8px 0;
  padding: 0;
  color: #333;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  line-height: 18px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list .item .info h5 .iconfont{
  color: #fb393d;
  font-size: 13px;
  font-weight: 500;
}
.list .item .info p {
  margin: 0;
  padding: 0;
  color: #999;
  font-size: 13px;
}
.list .item .info span {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 18px;
  font-weight: 700;
}
.list .item ul {
  margin-bottom: 1rem;
}
</style>
