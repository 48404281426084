<template>
  <h-nav :wild="true" />
  <div class="main row">
    <div class="library ten columns offset-by-one">
      <div class="content">
        <div class="loca">
          <div class="location">
            <div class="head">
              <img src="../assets/base_logo.png" />
              <div class="tags">
                <span class="tag"
                  >直播基地<span class="line"> | </span>品牌专场</span
                >
              </div>
            </div>
            <h5>选择城市</h5>
            <div class="select">
              <select v-model="province" @change="onProvince">
                <option disabled selected value>省</option>
                <option v-for="p in provinces" :key="p">{{ p }}</option>
              </select>
              <select v-model="city" @change="onCity">
                <option disabled selected value>市</option>
                <option v-for="c in cities" :key="c">{{ c }}</option>
              </select>
            </div>
          </div>
          <div class="map">
            <h-map
              :position="position"
              :markers="markers"
              @marker="onMarker"
            ></h-map>
          </div>
        </div>
        <div class="list">
          <div class="item" v-for="item in datas" :key="item.id">
            <div class="header" @click="onExpand(item)">
              <div class="line"></div>
              <p class="title">{{ item.name }}</p>
              <p
                v-if="active && active.id === item.id"
                class="expand iconfont icon-up1"
              >
                点击收起
              </p>
              <p v-else class="expand iconfont icon-down">点击展开</p>
            </div>
            <div class="box" v-if="active && active.id === item.id">
              <p class="desc">{{ item.desc }}</p>
              <div class="imgs" v-if="item.imgs.length > 0">
                <div
                  class="img"
                  v-for="(src, i) in item.imglist"
                  :key="i"
                  :style="src ? { background: 'url(' + src + ')' } : {}"
                  @click="onImg(item, src)"
                >
                  <div />
                </div>
              </div>
              <div class="row">
                <div class="left">类型：</div>
                <div class="right">
                  {{ item.type === "brand" ? "品牌直播间" : "直播基地" }}
                </div>
              </div>
              <div class="row">
                <div class="left">产品：</div>
                <div class="right">{{ item.products }}</div>
              </div>
              <div class="row">
                <div class="left">服务：</div>
                <div class="right">{{ item.services }}</div>
              </div>
              <div class="row">
                <div class="left">优势：</div>
                <div class="right">{{ item.feature }}</div>
              </div>
              <div class="button" @click="onItem(item)">
                申请排期
                <div class="pop-qr">
                  <div class="qr">
                    <p>打开小程序申请排期</p>
                    <img src="../assets/qr.png" alt="qr" />
                  </div>
                  <img class="tag" src="../assets/tag.png" alt="qr" />
                </div>
              </div>
            </div>
            <div v-else class="box">
              <p class="desc line">{{ item.desc }}</p>
            </div>
          </div>
          <h-loader
            :disable="page >= 0 && datas.length >= count"
            @load="loadData"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="place"></div>
  <h-footer :wild="true" />
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
import Loader from "../components/Loader.vue";
import Map from "../components/Map.vue";
import { request } from "../utils/request";

export default {
  name: "Library",
  components: {
    "h-nav": Nav,
    "h-footer": Footer,
    "h-map": Map,
    "h-loader": Loader,
  },
  data() {
    return {
      datas: [],
      active: null,
      loading: false,
      page: -1,
      locales: {},
      province: "",
      city: "",
      count: 0,
    };
  },
  computed: {
    markers() {
      return this.datas.map((i) => ({
        id: i.id,
        title: i.name,
        position: i.geo,
      }));
    },
    position() {
      return this.active ? this.active.geo : null;
    },
    provinces() {
      return Object.keys(this.locales);
    },
    cities() {
      return this.locales[this.province] || [];
    },
  },
  created() {
    this.loadCity();
  },
  methods: {
    onSearch() {
      this.active = null;
      this.loading = false;
      this.page = -1;
      this.count = 0;
      this.datas = [];
      this.loadData();
    },
    loadCity() {
      request("/bases/local", {}).then((r) => {
        this.locales = r;
        this.loadData();
      });
    },
    onProvince(e) {
      console.log("onProvince", e);
      this.city = this.cities[0] || "";
      this.onSearch();
    },
    onCity(e) {
      console.log("onCity", e);
      this.onSearch();
    },
    loadData() {
      if (this.loading || (this.page > 0 && this.datas.length >= this.count)) {
        return;
      }
      this.loading = true;
      let page = this.page + 1;
      request("/bases", {
        province: this.province,
        city: this.city,
        page,
        size: 10,
      })
        .then((r) => {
          this.page = page;
          this.count = r.count;
          this.datas = this.datas.concat(
            ...r.list.map((i) =>
              Object.assign(i, {
                imglist: i.imgs.concat("", "", "").slice(0, 4),
              })
            )
          );
          this.loading = false;
          if (!this.active) {
            this.active = this.datas[0] || {};
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log("error", e);
        });
    },
    onExpand(item) {
      if (this.active && this.active.id === item.id) {
        this.active = null;
      } else {
        this.active = item;
      }
    },
    onMarker(item) {
      let act = this.datas.find((i) => i.id === item.id)[0];
      if (act) {
        this.active = act;
      }
    },
    onImg() {},
  },
};
</script>

<style scoped>
.place{
  height: 80px;
}
.content {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 64px - 2rem);
  box-sizing: border-box;
}
.loca {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: left;
}
.loca .location .head {
  padding: 16px 0;
  display: flex;
  flex-basis: row;
  align-items: flex-end;
  justify-content: space-between;
}
.loca .location .head img {
  width: 180px;
  height: auto;
}

.loca .location .head .tags {
  padding-bottom: 8px;
}
.loca .location .head .tags .tag {
  display: inline-block;
  background-color: #fb393d;
  font-size: 12px;
  line-height: 18px;
  color: white;
  padding: 4px 8px;
  border-radius: 50rem;
}
.loca .location .head .tags .tag .line {
  font-size: 18px;
  line-height: 18px;
}
.loca .location h5 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.loca .location .select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.loca .location .select select {
  border: 1px solid #f2f2f2;
  width: 40%;
}
.loca .location .select select::after {
  border-color: #fb393d;
}
.loca .map {
  flex: 1;
  border-radius: 4px;
  overflow: hidden;
}
.list {
  width: 50%;
  height: 100%;
  overflow: hidden scroll;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
}
.item {
  padding: 12px 0;
  border-bottom: 1px solid #f2f2f2;
  text-align: left;
}
.list .item:last-of-type {
  border-bottom: none;
}
.item .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
.item .header .line {
  background-color: #fb393d;
  width: 4px;
  height: 16px;
  border-radius: 2px;
}
.item .header .title {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin: 0 0 0 8px;
}
.item .header .expand {
  font-size: 12px;
  font-weight: 500;
  color: #cccccc;
  margin: 0;
}
.item .header .iconfont {
  font-size: 12px;
}
.item .box {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}
.item .box .desc {
  font-weight: normal;
  color: #666;
  font-size: 15px;
  margin: 0;
}
.item .box .desc.line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item .box .imgs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
}
.item .box .img {
  width: 24%;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
}
.item .box .img > div {
  width: 100%;
  padding-top: 100%;
}
.item .box .row {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}
.item .box .row div {
  font-size: 15px;
  color: #333333;
}
.item .box .row .left {
  width: 48px;
  font-weight: bold;
}
.item .box .row .right {
  flex: 1;
  font-weight: normal;
  color: #666;
}
.item .box .button {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border-radius: 50rem;
  background-color: #fb393d;
  align-self: flex-end;
  border: none;
  position: relative;
}
.pop-qr {
  position: absolute;
  bottom: 48px;
  left: -8px;
  display: none;
  flex-direction: column;
  align-items: center;
}
.item .box .button:hover .pop-qr {
  display: flex;
}
.item .box .button .pop-qr:hover {
  display: flex;
}
.pop-qr .qr {
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0 8px 8px 8px;
  box-sizing: border-box;
  box-shadow: 0px 11px 31px 0px rgba(57, 57, 57, 0.21);
}
.pop-qr .qr img {
  width: 120px;
  height: 120px;
}
.pop-qr .qr p {
  margin: 0;
  font-size: 10px;
  color: #333;
}
.pop-qr .tag {
  width: 10px;
  height: 6px;
  margin-bottom: -6px;
}
</style>
