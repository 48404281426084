<template>
  <h-nav />
  <div class="main">
    <div class="content">
      <p>页面不存在</p>
    </div>
  </div>
  <h-footer />
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Notfound",
  components: {
    "h-nav": Nav,
    "h-footer": Footer,
  },
};
</script>

<style scoped>
.content {
  margin: 1rem 0;
  min-height: calc(100vh - 324px - 2rem);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
