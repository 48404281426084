<template>
  <h-nav :wild="true" />
  <div class="main row">
    <div class="library ten columns offset-by-one">
      <div class="content">
        <div class="box">
          <div class="imgs">
            <div class="slider">
              <h-swiper
                class="swiper h-pagination"
                :slides-per-view="1"
                :space-between="10"
                :pagination="{ clickable: true }"
              >
                <h-swiperslide
                  class="slide"
                  v-for="(url, i) in data.imageUrls"
                  :key="i"
                >
                  <img class="img" :src="url" />
                </h-swiperslide>
              </h-swiper>
            </div>
            <div class="action">
              <div
                class="button"
                :class="{ tip }"
                ref="copy"
                :data-clipboard-text="data.itemId"
              >
                复制ID
                <div class="pop-qr">
                  <div class="qr">
                    <p>复制成功</p>
                  </div>
                  <img class="tag" src="../assets/tag.png" alt="qr" />
                </div>
              </div>
              <div class="button pop">
                一键上架
                <div class="pop-qr" v-if="data.id">
                  <div class="qr">
                    <p>打开小程序一键上架</p>
                    <img
                      :src="
                        '/api/weapi/qr?page=pages%2Fdetail%2Findex&scene=i%3D' + data.id
                      "
                      alt="qr"
                    />
                  </div>
                  <img class="tag" src="../assets/tag.png" alt="qr" />
                </div>
              </div>
              <div class="button pop">
                免费申样
                <div class="pop-qr" v-if="data.id">
                  <div class="qr">
                    <p>打开小程序申请样品</p>
                    <img
                      :src="
                        '/api/weapi/qr?page=pages%2Fdetail%2Findex&scene=i%3D' + data.id
                      "
                      alt="qr"
                    />
                  </div>
                  <img class="tag" src="../assets/tag.png" alt="qr" />
                </div>
              </div>
            </div>
          </div>
          <div class="info">
            <h5>
              <span
                v-if="data.plant === 'douyin'"
                class="iconfont icon-douyin1"
              ></span>
              <span v-else class="iconfont icon-kuaishou"></span>
              {{ data.title }}
            </h5>
            <div class="row">
              <div class="price">
                <p>
                  ¥<span class="big">{{ price }}</span>
                </p>
                <p>
                  预估赚<span>¥{{ incom }}</span>
                </p>
              </div>
              <div class="comm">
                <span>佣金: {{ comm }}%</span>
              </div>
            </div>
            <div class="block">
              <h6>产品规格</h6>
              <table>
                <tr>
                  <th>规格</th>
                  <th>价格</th>
                </tr>
                <tr v-for="sku in data.skuList" :key="sku.skuId">
                  <td>{{ sku.specification }}</td>
                  <td>¥{{ sku.skuSalePrice / 100 }}</td>
                </tr>
              </table>
            </div>
            <div class="block">
              <h6>产品卖点</h6>
              <pre>{{ (data.ht_ext || {}).feature || "" }}</pre>
            </div>
            <div class="block">
              <h6>快递信息</h6>
              <pre>{{ (data.ht_ext || {}).delivery || "" }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h-footer :wild="true" />
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
import SwiperCore, { Pagination, Mousewheel, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
import { request } from "../utils/request";
import ClipboardJS from "clipboard";

SwiperCore.use([Pagination, Mousewheel, Autoplay]);

export default {
  name: "Detail",
  components: {
    "h-nav": Nav,
    "h-footer": Footer,
    "h-swiper": Swiper,
    "h-swiperslide": SwiperSlide,
  },
  data() {
    return {
      id: "",
      tip: false,
      data: {},
      loading: false,
    };
  },
  computed: {
    price() {
      return (this.data.price / 100).toFixed(2);
    },
    comm() {
      return this.data.teamcommission / 10;
    },
    incom() {
      return ((this.data.price * this.data.teamcommission) / 100000).toFixed(2);
    },
  },
  watch: {
    $route() {
      const { id } = this.$route.params;
      if (id && id !== this.id) {
        this.loadData();
      }
    },
  },
  created() {
    const { id } = this.$route.params;
    this.id = id;
    this.loadData();
  },
  mounted() {
    this.clip = new ClipboardJS(this.$refs.copy);
    this.clip.on("success", () => {
      this.tip = true;
      setTimeout(() => {
        this.tip = false;
      }, 1000);
    });
  },
  beforeUnmount() {
    if (this.clip) {
      this.clip.destroy();
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      request("/products/detail/" + this.id)
        .then((r) => {
          this.data = r;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.content {
  margin: 1rem 0;
  min-height: calc(100vh - 64px - 2rem);
  box-sizing: border-box;
}
.box {
  display: flex;
  flex-direction: row;
}
.box .imgs {
  width: 40%;
}
.box .imgs .slider {
  width: 100%;
  position: relative;
}
.box .imgs .slider::before {
  content: " ";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.box .imgs .swiper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.box .imgs .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.box .info {
  width: 60%;
  box-sizing: border-box;
  padding: 16px 0 16px 16px;
  text-align: left;
}
.box .info h5 {
  font-weight: 500;
  font-size: 16px;
  color: #333;
  word-break: break-all;
}
.box .info h5 .iconfont{
  color: #fb393d;
  font-size: 16px;
  font-weight: 500;
}
.box .info .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.box .info .row > div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex: 1;
}
.box .info .row p {
  margin: 0 12px 8px 0;
  padding: 0;
}
.box .info .price {
  font-size: 18px;
  font-weight: bold;
  color: #666;
}
.box .info .price span.big {
  font-size: 36px;
}
.box .info .price span {
  color: #fb393d;
}
.box .info .comm span {
  line-height: 1.5;
  color: white;
  font-size: 16px;
  background-color: #fb393d;
  padding: 0 12px;
  border-radius: 50rem;
}
.box .info .block h6 {
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: bold;
}
.box .info .block table {
  min-width: 70%;
  text-align: center;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}
.box .info .block table td,
.box .info .block table th {
  padding: 8px 0;
  text-align: center;
  border: 1px solid #f2f2f2;
}
.box .info .block pre {
  white-space: pre-wrap;
  margin: 0 0 8px 0;
}
.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.action .button {
  width: 30%;
  padding: 0 8px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border-radius: 50rem;
  background-color: #fb393d;
  border: none;
  position: relative;
}
.action .button:first-of-type {
  background-color: rgb(138, 234, 234);
}
.action .button:last-of-type {
  background-color: rgb(253, 193, 97);
}
.pop-qr {
  position: absolute;
  bottom: 48px;
  left: 0;
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  z-index: 99;
}
.action .button.pop:hover .pop-qr {
  display: flex;
}
.action .button.pop .pop-qr:hover {
  display: flex;
}
.action .button.tip .pop-qr {
  display: flex;
}
.action .button.tip .pop-qr .qr {
  padding: 0;
}
.pop-qr .qr {
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0 8px 8px 8px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0px 11px 31px 0px rgba(57, 57, 57, 0.21);
}
.pop-qr .qr img {
  width: 100%;
  height: auto;
}
.pop-qr .qr p {
  margin: 0;
  font-size: 10px;
  color: #333;
  text-align: center;
}
.pop-qr .tag {
  width: 10px;
  height: 6px;
  margin-bottom: -6px;
}
</style>
