import { createApp } from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import App from './App.vue'
import routes from './views/index'

let app = createApp(App)

let router = createRouter({
    history: createWebHistory(),
    routes
})

app.use(router)

app.mount('#app')