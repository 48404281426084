<template>
  <h-nav />
  <div class="main">
    <div class="content">
      <div class="row">
        <div class="section eight columns offset-by-two">
          <h3>关于我们</h3>
          <p>
            杭州红透网络科技有限公司位于电商之都杭州，旗下红透网络直播电商服务平台，涵盖：选品平台、直播数据平台、电商运营服务以及直播基地服务平台。致力于提升行业效率，为达人提供相匹配供应链及电商运营服务，实现达人更大价值产出。为厂商品牌方提供精准达人渠道及直播电商全案推广方案。平台通过数据+专业服务实现更精准的匹配方案，全面提升各方效率，快速打爆，红透网络。
          </p>

          <div class="contact">
            <div class="button">联系我们</div>
            <img class="img" src="../assets/contact.jpg" alt="qr" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <h-footer />
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Notfound",
  components: {
    "h-nav": Nav,
    "h-footer": Footer,
  },
};
</script>

<style scoped>
.main {
  background-image: url(../assets/about.jpg);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}
.content {
  box-sizing: border-box;
  padding-top: 10%;
}
.contact {
  display: flex;
  flex-direction: column-reverse;
  width: 160px;
  margin: 160px auto 40px auto;
  box-sizing: border-box;
}
.contact .button {
  border: none;
  color: #eee;
  background-color: #fb393d;
  font-size: 13px;
  border-radius: 4px;
  border-radius: 50rem;
}
.contact .button:hover {
  color: white;
}
.contact .button:hover ~ .img {
  opacity: 1;
}
.contact .img {
  width: 160px;
  padding-bottom: 6px;
  opacity: 0;
  border-radius: 4px;
}
.contact .img:hover {
  opacity: 1;
}
</style>
