<template>
  <div class="loader" v-show="!disable">
      <p>加载中...</p>
    <!-- <slot>
      <svg viewBox="25 25 50 50" class="loader__svg">
        <circle cx="50" cy="50" r="20" class="loader__circle"></circle>
      </svg>
    </slot> -->
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    observer() {
      return new IntersectionObserver(
        ([{ isIntersecting }]) => {
          isIntersecting && !this.disable && this.$emit("load");
        },
        { rootMargin: "0px", threshold: 0.1 }
      );
    },
  },
  mounted() {
    this.observer.observe(this.$el);
  },
  activated() {
    this.observer.observe(this.$el);
  },
  deactivated() {
    this.observer.unobserve(this.$el);
  },
  beforeUnmount() {
    this.observer.unobserve(this.$el);
  },
};
</script>

<style scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
}
.loader p{
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #999;
}
</style>